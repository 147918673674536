import { Theme, withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LabelProps {
    classes: any,
    value: string,
    isDisabled?: boolean
}

const { palette }  = theme

const styles: Styles<Theme, {}, string> = {
    root: {
      fontSize: '16px',
      letterSpacing: '0.32px',
      lineHeight: '20px',
      fontFamily: '"Assistant", sans-serif',
      color: palette.NEUTRAL_700,
      fontWeight: 700,
      paddingBottom: '4px'
    },
    disabled: {
      color: palette.NEUTRAL_200
    }
  };

export const LabelElem: React.FC<LabelProps> = ({
    ...props
}) => {
    const { classes, value, isDisabled } = props
    return <label className={`${classes.root} ${isDisabled && classes.disabled}`}>{value}</label>
}

export const Label = withStyles(styles)(LabelElem)