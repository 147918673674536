export const occupations = [
    {
        value: 'v10173',
        label: 'AA Patrolman',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10174',
        label: 'Abattoir Inspector',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10175',
        label: 'Accountant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10176',
        label: 'Accounts Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10177',
        label: 'Actor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10178',
        label: 'Actress',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10179',
        label: 'Actuary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10180',
        label: 'Acupuncturist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10181',
        label: 'Administration Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10182',
        label: 'Administration Worker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10183',
        label: 'Adult Education Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10184',
        label: 'Advertising Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10185',
        label: 'Advertising Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10186',
        label: 'Advertising Salesperson',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10187',
        label: 'Aerial Erector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10188',
        label: 'Aerial Photographer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10189',
        label: 'Aerial Rigger',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10190',
        label: 'Aerobics Instructor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10191',
        label: 'Aeroplane Maintenance Technician',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10192',
        label: 'Agricultural Advisor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10193',
        label: 'Agricultural Contractor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10194',
        label: 'Agricultural Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10195',
        label: 'Agriculturist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10196',
        label: 'AI Man',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10197',
        label: 'Air Conditioning Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10198',
        label: 'Air Hostess',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10199',
        label: 'Air Steward',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10200',
        label: 'Air Traffic Controller',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10201',
        label: 'Aircraft Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10202',
        label: 'Aircraft Mechanic/Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10203',
        label: 'Aircraft Pilot (Commercial)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10204',
        label: 'Airline baggage handler',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10205',
        label: 'Airline Executive',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10206',
        label: 'Airline Ground Staff',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10207',
        label: 'Airline Pilot',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10208',
        label: 'Airlines - Cabin Crew',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10209',
        label: 'Airlines - Flying Crew',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10210',
        label: 'Airport Police',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10211',
        label: 'Ambulance Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10212',
        label: 'Ambulance Paramedic',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10213',
        label: 'Anaesthetist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10214',
        label: 'Analyst Programmer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10215',
        label: 'Animator',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10216',
        label: 'Antique Dealer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10217',
        label: 'Archaeologist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10218',
        label: 'Architect',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10219',
        label: 'Architectural Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10220',
        label: 'Architectural Technician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10221',
        label: 'Area Sales Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10222',
        label: 'Army (Irish)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10223',
        label: 'Aromatherapist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10224',
        label: 'Art Dealer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10225',
        label: 'Art Director',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10226',
        label: 'Art Gallery Attendant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10228',
        label: 'Artist',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10229',
        label: 'Asbestos Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10230',
        label: 'Assembly Line Worker - Heavy Manual',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10231',
        label: 'Assembly Line Worker - Light Manual',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10233',
        label: 'Assistant Hotel Manager',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10234',
        label: 'Assistant Manager (Retail)',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10235',
        label: 'Astrologer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10236',
        label: 'Astronomer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10237',
        label: 'Attorney',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10238',
        label: 'Au Pair',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10239',
        label: 'Auctioneer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10240',
        label: 'Audio Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10241',
        label: 'Audio Typist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10242',
        label: 'Auditor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10243',
        label: 'Author',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10244',
        label: 'Aviation Engineer (Irish Army)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10245',
        label: 'Aviation Engineer (Commercial)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10246',
        label: 'B&amp;B Proprietor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10247',
        label: 'Baggage Handler',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10248',
        label: 'Bailiff',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10249',
        label: 'Baker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10250',
        label: 'Bakery Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10251',
        label: 'Bank Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10252',
        label: 'Bank Official',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10253',
        label: 'Bank Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10254',
        label: 'Banker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10255',
        label: 'Bar Manager',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10256',
        label: 'Barber',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10257',
        label: 'Barman/Barmaid',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10258',
        label: 'Barrelman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10259',
        label: 'Barrister',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10260',
        label: 'Beautician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10261',
        label: 'Beauty Consultant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10262',
        label: 'Beekeeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10263',
        label: 'Benefit Assessor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10264',
        label: 'Betting Office Clerk',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10265',
        label: 'Betting Office Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10266',
        label: 'Binman',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10267',
        label: 'Biochemist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10268',
        label: 'Biological Researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10269',
        label: 'Biologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10270',
        label: 'Blacksmith',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10271',
        label: 'Blind Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10272',
        label: 'Boatbuilder',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10273',
        label: 'Bodyguard',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10274',
        label: 'Boiler',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10275',
        label: 'Boiler Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10276',
        label: 'Bomb Disposal Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10277',
        label: 'Boner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10278',
        label: 'Book Binder',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10279',
        label: 'Book Shop Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10280',
        label: 'Book Shop Owner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10281',
        label: 'Book-Keeper',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10282',
        label: 'Bookmaker (On Course)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10283',
        label: 'Bookmaker (Shop)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10284',
        label: 'Bouncer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10285',
        label: 'Boutique Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10286',
        label: 'Boutique Owner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10287',
        label: 'Box Office Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10288',
        label: 'Box Office Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10289',
        label: 'Branch Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10290',
        label: 'Bread Baker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10291',
        label: 'Bread Roundsman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10292',
        label: 'Brewery Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10293',
        label: 'Brewery Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10294',
        label: 'BrickLayer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10295',
        label: 'Bridge Builder',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10296',
        label: 'Bridge Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10297',
        label: 'Broadcaster',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10298',
        label: 'Broker (Insurance)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10299',
        label: 'Broker Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10300',
        label: 'Brokerage Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10301',
        label: 'Builder',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10302',
        label: 'Builder Labourer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10303',
        label: 'Building Contractor - Manual Work',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10304',
        label: 'Building Contractor - No Manual Work',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10305',
        label: 'Building Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10306',
        label: 'Building Inspector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10307',
        label: 'Building Site Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10308',
        label: 'Building Society Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10309',
        label: 'Building Society Executive',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10310',
        label: 'Building Society Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10311',
        label: 'Building Surveyor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10312',
        label: 'Bulldozer Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10314',
        label: 'Bus Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10315',
        label: 'Bus Inspector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10316',
        label: 'Business Administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10317',
        label: 'Business Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10318',
        label: 'Business Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10319',
        label: 'Business Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10320',
        label: 'Business Research Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10321',
        label: 'Butcher - No Slaughtering',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10322',
        label: 'Butcher - Slaughtering',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10325',
        label: 'Cab Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10326',
        label: 'Cabin Crew (Commercial)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10327',
        label: 'Cabinet Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10328',
        label: 'Cable Hand',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10329',
        label: 'Cable Layer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10330',
        label: 'Cable TV Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10331',
        label: 'Cafe Proprietor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10332',
        label: 'Cafe Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10333',
        label: 'Calligrapher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10334',
        label: 'Cameraman (Films Television)',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10335',
        label: 'Cameraman (Journalism)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10336',
        label: 'Canal Inspector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10337',
        label: 'Candle Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10338',
        label: 'Canteen Cashier',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10339',
        label: 'Canteen Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10340',
        label: 'Canteen Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10341',
        label: 'Captain (Merchant Marine)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10342',
        label: 'Captain Aviation (Commercial)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10343',
        label: 'Car Assembly Line Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10344',
        label: 'Car Body Repairer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10345',
        label: 'Car Dealer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10346',
        label: 'Car Mechanic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10347',
        label: 'Car Paint Sprayer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10348',
        label: 'Car Park Attendant',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10349',
        label: 'Car Salesperson',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10350',
        label: 'Car Valeter',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10351',
        label: 'Cardiologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10352',
        label: 'Cardiovascular Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10353',
        label: 'Care Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10354',
        label: 'Careers Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10355',
        label: 'Careers Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10356',
        label: 'Caretaker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10357',
        label: 'Cargo Checker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10358',
        label: 'Cargo Reservation Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10359',
        label: 'Cargo Superintendent',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10360',
        label: 'Carpenter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10361',
        label: 'Carpet Layer/Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10362',
        label: 'Carpet Retailer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10363',
        label: 'Cartographer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10365',
        label: 'Cartoonist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10366',
        label: 'Cash Room Assistant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10367',
        label: 'Cash Room Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10368',
        label: 'Cash Room Supervisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10369',
        label: 'Cashier',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10371',
        label: 'Caterer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10372',
        label: 'Catering Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10373',
        label: 'Catering Officer (Other)',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10374',
        label: 'Catering Staff (Oil/Gas Rigs)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10375',
        label: 'Catering Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10376',
        label: 'Ceiling Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10377',
        label: 'Cellar Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10378',
        label: 'Cemetery Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10379',
        label: 'Central Heating Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10380',
        label: 'Central Heating Installer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10381',
        label: 'Certified Accountant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10382',
        label: 'Chartered Accountant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10383',
        label: 'Chartered Architect',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10384',
        label: 'Chartered Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10385',
        label: 'Chartered Surveyor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10386',
        label: 'Chauffeur',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10387',
        label: 'Check Out Assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10388',
        label: 'Check Out Supervisor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10390',
        label: 'Cheesemonger',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10391',
        label: 'Chef',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10392',
        label: 'Chemical Engineer (Oil/Gas Rigs)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10393',
        label: 'Chemical Engineering Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10394',
        label: 'Chemical Researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10395',
        label: 'Chemist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10396',
        label: 'Chemist (Oil/Gas Rigs)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10397',
        label: 'Chief Executive Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10398',
        label: 'Child Care Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10399',
        label: 'Child Minder',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10401',
        label: 'Chimney Sweep',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10402',
        label: 'Chip Shop Assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10403',
        label: 'Chiropodist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10404',
        label: 'Chiropractor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10405',
        label: 'Choreographer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10406',
        label: 'CIE Conductor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10407',
        label: 'CIE Guard',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10408',
        label: 'CIE Rail Trackman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10409',
        label: 'CIE Train Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10410',
        label: 'Cinema Staff',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10411',
        label: 'Civil Defence Officer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10412',
        label: 'Civil Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10413',
        label: 'Civil Servant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10414',
        label: 'Claims Assessor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10415',
        label: 'Class Room Assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10416',
        label: 'Cleaner (Windows) &lt; 30 ft',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10417',
        label: 'Cleaner (Windows) &gt; 30 ft',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10418',
        label: 'Cleaning Lady',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10419',
        label: 'Clergyman',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10420',
        label: 'Clerical Assistant - Civil Service',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10421',
        label: 'Clerical Officer - Civil Service',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10422',
        label: 'Clerk/Clerical Worker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10423',
        label: 'Clinic Nurse',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10424',
        label: 'Clock Maker/Repairer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10425',
        label: 'Club Owner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10426',
        label: 'Club Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10427',
        label: 'Coach Builder',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10428',
        label: 'Coach Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10429',
        label: 'Coal Deliveryperson',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10430',
        label: 'Coal Merchant',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10431',
        label: 'Coal Miner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10432',
        label: 'Coal Yard Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10433',
        label: 'Coastguard',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10434',
        label: 'Cobbler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10435',
        label: 'Coffin Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10436',
        label: 'College Administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10437',
        label: 'College Lecturer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10438',
        label: 'College Student',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10439',
        label: 'Columnist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10440',
        label: 'Comedian',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10441',
        label: 'Commercial Artist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10442',
        label: 'Commercial Pilot',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10443',
        label: 'Commercial Traveller',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10444',
        label: 'Commis Chef',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10445',
        label: 'Commissioner Of Oaths',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10446',
        label: 'Commodity Broker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10447',
        label: 'Community Nurse',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10448',
        label: 'Community Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10449',
        label: 'Company Accountant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10450',
        label: 'Company Director - non-manual',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10451',
        label: 'Company Executive',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10452',
        label: 'Company Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10453',
        label: 'Compliance Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10454',
        label: 'Compliant Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10456',
        label: 'Computer Aided Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10457',
        label: 'Computer Assembler',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10458',
        label: 'Computer Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10459',
        label: 'Computer Operations Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10460',
        label: 'Computer Operator/Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10461',
        label: 'Computer Programmer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10462',
        label: 'Computer Project Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10464',
        label: 'Computer Software Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10465',
        label: 'Computer Systems Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10466',
        label: 'Concrete Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10468',
        label: 'Conductor (Music)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10469',
        label: 'Confectioner',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10470',
        label: 'Construction Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10471',
        label: 'Construction Site Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10472',
        label: 'Construction Worker &lt; 30 feet',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10473',
        label: 'Construction Worker &gt; 30 feet',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10474',
        label: 'Consultant Anaesthetist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10475',
        label: 'Consultant Cardiologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10476',
        label: 'Consultant Gynaecologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10477',
        label: 'Consultant Physician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10478',
        label: 'Consultant Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10479',
        label: 'Contract Cleaner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10480',
        label: 'Conveyor Belt Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10481',
        label: 'Cook',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10482',
        label: 'Cook (Merchant Marine)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10483',
        label: 'Coppersmith',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10484',
        label: 'Copywriter',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10485',
        label: 'Coroner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10486',
        label: 'Costume Designer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10487',
        label: 'Council Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10488',
        label: 'Council Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10489',
        label: 'Counsellor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10490',
        label: 'County Council Worker - Non Manual',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10491',
        label: 'County Council Worker - Manual',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10492',
        label: 'Courier Motor Bike',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10493',
        label: 'Courier Van Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10494',
        label: 'Court Bailiff',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10495',
        label: 'Court Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10496',
        label: 'Court Usher',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10497',
        label: 'Crane Driver - &gt; 15 feet',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10498',
        label: 'Crane Driver - Ground Level',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10499',
        label: 'Creche Owner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10500',
        label: 'Credit Controller',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10501',
        label: 'Credit Union Cashier',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10502',
        label: 'Curtain Fitter/Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10503',
        label: 'Customer Service Worker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10504',
        label: 'Customs &amp; Excise Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10505',
        label: 'Customs And Excise Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10506',
        label: 'Dairy Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10507',
        label: 'Dance Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10508',
        label: 'Data Processing Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10509',
        label: 'Debt Collector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10510',
        label: 'Deck Officer (Irish Army)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10511',
        label: 'Deckhand (Fishing)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10512',
        label: 'Deep Sea Diver (Commercial)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10513',
        label: 'Defence Forces Member',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10514',
        label: 'Delivery Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10515',
        label: 'Demolition Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10516',
        label: 'Demonstrator (Sales)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10517',
        label: 'Dental Nurse',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10518',
        label: 'Dental Receptionist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10519',
        label: 'Dental Surgeon',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10520',
        label: 'Dental Technician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10521',
        label: 'Dermatologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10522',
        label: 'Design Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10523',
        label: 'Designer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10524',
        label: 'Desk Top Publisher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10525',
        label: 'Detective (Police)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10526',
        label: 'Detective (Private)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10527',
        label: 'Detective (Store)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10528',
        label: 'Dietician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10529',
        label: 'Digger Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10530',
        label: 'Dinner Lady',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10531',
        label: 'Diplomat',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10533',
        label: 'Disc Jockey',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10534',
        label: 'Dispatch Controller',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10535',
        label: 'Dispensing Chemist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10536',
        label: 'Dispensing Optician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10537',
        label: 'District Midwife',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10539',
        label: 'District Planner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10540',
        label: 'Diver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10541',
        label: 'Docker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10542',
        label: 'Doctor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10543',
        label: 'Doctors Receptionist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10544',
        label: 'Domestic Cleaner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10545',
        label: 'Domestic Electrician',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10546',
        label: 'Doorman (Club)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10547',
        label: 'Double Glazing Installer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10548',
        label: 'Double Glazing Salesperson',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10549',
        label: 'Drama Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10550',
        label: 'Draper',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10551',
        label: 'Draughtsman',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10552',
        label: 'Dress Maker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10553',
        label: 'Driver (Bus)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10554',
        label: 'Driver (HGV)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10555',
        label: 'Driver (Taxi)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10556',
        label: 'Driver (Van)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10557',
        label: 'Driving Instructor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10558',
        label: 'Driving Test Examiner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10559',
        label: 'Dry Cleaner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10560',
        label: 'Dumper Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10561',
        label: 'Dustman',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10562',
        label: 'Economist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10563',
        label: 'Eircom Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10564',
        label: 'Eircom Operator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10565',
        label: 'Elderly Care Assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10566',
        label: 'Electrical Contractor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10567',
        label: 'Electrical Draughtsman',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10568',
        label: 'Electrical Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10569',
        label: 'Electrical Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10570',
        label: 'Electrical Retailer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10571',
        label: 'Electrician - Domestic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10572',
        label: 'Electrician - Industrial',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10573',
        label: 'Electronic Goods Assembler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10574',
        label: 'Electronics Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10575',
        label: 'Employment Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10576',
        label: 'Endocrinologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10577',
        label: 'Engineer Civil',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10578',
        label: 'Engineer Electrical',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10579',
        label: 'Engineer Mechanical',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10580',
        label: 'Engineer Structural',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10581',
        label: 'Engraver',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10582',
        label: 'Environmental Health Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10583',
        label: 'Estate Agent',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10584',
        label: 'Excavator Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10585',
        label: 'Executive Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10586',
        label: 'Exhaust Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10587',
        label: 'Exhibition Co-ordinator',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10588',
        label: 'Export Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10589',
        label: 'Factory Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10590',
        label: 'Factory Worker - Heavy Manual',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10591',
        label: 'Factory Worker - Light Manual',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10592',
        label: 'Farm Labourer/Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10593',
        label: 'Farm Manager',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10594',
        label: 'Farmer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10595',
        label: 'Farrier',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10596',
        label: 'Fas Student',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10597',
        label: 'Fashion Buyer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10598',
        label: 'Fashion Designer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10599',
        label: 'Felt Roofer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10600',
        label: 'Fence Erector',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10601',
        label: 'Filing Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10602',
        label: 'Film Processor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10603',
        label: 'Finance Broker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10604',
        label: 'Finance Director',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10605',
        label: 'Financial Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10606',
        label: 'Financial Controller',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10607',
        label: 'Fireperson',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10608',
        label: 'First Officer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10609',
        label: 'Fish And Chip Shop Owner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10610',
        label: 'Fish Factory Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10611',
        label: 'Fish Farmer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10612',
        label: 'Fisherman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10613',
        label: 'Fishmonger',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10614',
        label: 'Fitness Instructor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10615',
        label: 'Fitted Kitchen Salesman',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10616',
        label: 'Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10617',
        label: 'Flat Racing Jockey',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10618',
        label: 'Floor Layer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10619',
        label: 'Floor Tiler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10620',
        label: 'Flour Miller',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10621',
        label: 'Foreign Correspondent',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10622',
        label: 'Foreman - No Manual Work',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10623',
        label: 'Foreman - Some Manual Work',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10624',
        label: 'Forestry Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10625',
        label: 'Forge Assistant',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10626',
        label: 'Fork Lift Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10627',
        label: 'Freelance Journalist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10628',
        label: 'Freight Controller',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10629',
        label: 'French Polisher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10630',
        label: 'Fruit Grower',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10631',
        label: 'Fruit Salesman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10632',
        label: 'Funeral Director',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10633',
        label: 'Furnace Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10634',
        label: 'Furniture Assembler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10635',
        label: 'Furniture Remover',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10636',
        label: 'Furniture Shop Owner',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10637',
        label: 'Ganger',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10638',
        label: 'Garage Mechanic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10639',
        label: 'Garage Owner/Manager Non-Manual',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10640',
        label: 'Garbage Collector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10641',
        label: 'Garda',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10642',
        label: 'Garda Sergeant',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10643',
        label: 'Garda Superintendent',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10644',
        label: 'Gardener',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10645',
        label: 'Gas Board Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10646',
        label: 'Gas Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10647',
        label: 'Gas Meter Reader',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10648',
        label: 'Gas Meter Tester',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10649',
        label: 'Gate Keeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10650',
        label: 'Genealogist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10651',
        label: 'General Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10652',
        label: 'General Labourer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10653',
        label: 'General Operative - Light Manual',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10654',
        label: 'General Operative - Heavy Manual',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10655',
        label: 'General Practitioner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10656',
        label: 'Geneticist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10657',
        label: 'Geologist (Oil/Gas Rigs)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10658',
        label: 'Geologist (Other)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10659',
        label: 'Glass Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10660',
        label: 'Glazier',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10661',
        label: 'Government Official',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10662',
        label: 'Graphic Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10663',
        label: 'Grave Digger',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10664',
        label: 'Green Keeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10665',
        label: 'GreenGrocer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10666',
        label: 'Groom',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10667',
        label: 'Ground Steward',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10668',
        label: 'Guest House Proprietor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10669',
        label: 'Gym Instructor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10670',
        label: 'Gynaecologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10671',
        label: 'Hackney Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10672',
        label: 'Hairdresser',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10673',
        label: 'Harbour Pilot',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10674',
        label: 'Haulage Contractor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10675',
        label: 'Head Chef',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10677',
        label: 'Health And Safety Consultant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10678',
        label: 'Health Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10679',
        label: 'Healthcare Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10680',
        label: 'Heating Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10681',
        label: 'Heavy Goods Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10682',
        label: 'Herbalist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10683',
        label: 'Hgv Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10684',
        label: 'Higher Executive Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10685',
        label: 'Historian',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10686',
        label: 'Home Care Assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10687',
        label: 'Home Maker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10688',
        label: 'Homeopath',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10689',
        label: 'Horse Breeder',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10690',
        label: 'Horse Riding Instructor',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10691',
        label: 'Horticulturist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10692',
        label: 'Hospital Attendant',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10693',
        label: 'Hospital Porter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10694',
        label: 'Hospital Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10695',
        label: 'Hotel Chef',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10696',
        label: 'Hotel Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10697',
        label: 'Hotel Porter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10698',
        label: 'Hotel Proprietor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10699',
        label: 'House Decorator',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10700',
        label: 'Househusband',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10701',
        label: 'Housekeeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10702',
        label: 'Housewife',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10703',
        label: 'Housing Inspector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10704',
        label: 'Human Resources Administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10705',
        label: 'Hypnotherapist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10706',
        label: 'Ice Cream Vendor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10707',
        label: 'Immigration Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10708',
        label: 'Import Administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10709',
        label: 'Independent Financial Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10710',
        label: 'Industrial Cleaner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10711',
        label: 'Industrial Relations Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10713',
        label: 'Inspector Of Taxes',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10714',
        label: 'Instore Demonstrator',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10715',
        label: 'Insurance Agent',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10716',
        label: 'Insurance Assessor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10717',
        label: 'Insurance Associate',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10718',
        label: 'Insurance Broker/Agent',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10719',
        label: 'Insurance Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10720',
        label: 'Insurance Salesperson',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10721',
        label: 'Insurance Staff',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10722',
        label: 'Insurance Underwriter',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10723',
        label: 'Interior Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10724',
        label: 'Internal Auditor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10725',
        label: 'Interpreter',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10726',
        label: 'Investment Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10727',
        label: 'Investment Banker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10728',
        label: 'Investment Broker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10729',
        label: 'Investment Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10730',
        label: 'Ironmonger',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10731',
        label: 'IT Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10732',
        label: 'IT Programmer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10733',
        label: 'Janitor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10734',
        label: 'JCB Digger Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10735',
        label: 'Jeweller - Repairs/Making',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10736',
        label: 'Jewellery Designer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10737',
        label: 'Jewelry Retailer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10738',
        label: 'Jiggerman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10739',
        label: 'Jockey',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10740',
        label: 'Joiner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10741',
        label: 'Journalist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10742',
        label: 'Judge',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10743',
        label: 'Keep Fit Instructor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10744',
        label: 'Kitchen Assistant',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10745',
        label: 'Kitchen Porter',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10746',
        label: 'Kitchen Supplier Fitter',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10747',
        label: 'Lab. Technician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10748',
        label: 'Labourer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10749',
        label: 'Land Surveyor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10750',
        label: 'Landlord',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10751',
        label: 'Landscape Gardener',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10752',
        label: 'Laundry Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10753',
        label: 'Lawyer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10754',
        label: 'Lecturer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10755',
        label: 'Legal Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10756',
        label: 'Legal Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10757',
        label: 'Leisure Centre Attendant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10758',
        label: 'Letting Agent',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10759',
        label: 'Librarian',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10760',
        label: 'Licensed Victualler',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10761',
        label: 'Life Assurance Broker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10762',
        label: 'Life Assurance Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10763',
        label: 'Life Assurance Salesperson',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10764',
        label: 'Life Guard',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10765',
        label: 'Lifeboat Man',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10766',
        label: 'Lift Repair person',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10767',
        label: 'Lighting Technician',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10768',
        label: 'Linesman',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10769',
        label: 'Lithographer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10770',
        label: 'Litigation Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10771',
        label: 'Lobster Fisherman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10772',
        label: 'Local Council Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10773',
        label: 'Locksmith',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10774',
        label: 'Locomotive Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10775',
        label: 'Lollipop Lady',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10776',
        label: 'Lorry Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10777',
        label: 'Loss Adjuster',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10778',
        label: 'Loss Assessor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10779',
        label: 'Lumberjack',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10780',
        label: 'Machine Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10781',
        label: 'Machinist',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10782',
        label: 'Magazine Editor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10783',
        label: 'Magistrate',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10784',
        label: 'Maintenance Engineer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10785',
        label: 'Maintenance Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10786',
        label: 'Maintenance Technician (Oil/Gas Rigs)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10787',
        label: 'Make Up Artist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10788',
        label: 'Management Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10789',
        label: 'Manager - clerical',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10790',
        label: 'Map Maker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10791',
        label: 'Marine Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10792',
        label: 'Marine Maintenance Fitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10793',
        label: 'Market Gardener',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10794',
        label: 'Market Researcher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10795',
        label: 'Market Trader',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10796',
        label: 'Marketing Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10797',
        label: 'Marketing Assistant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10798',
        label: 'Marketing Executive',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10799',
        label: 'Mason',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10800',
        label: 'Masonry Restorer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10801',
        label: 'Master Butcher',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10802',
        label: 'Mathematician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10803',
        label: 'Matron (Nursing)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10804',
        label: 'Mature Student',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10806',
        label: 'Mechanic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10807',
        label: 'Mechanical Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10809',
        label: 'Medical Practitioner',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10810',
        label: 'Medical Representative',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10811',
        label: 'Medical Researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10812',
        label: 'Medical Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10813',
        label: 'Member Of European Parliament',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10814',
        label: 'Member Of The Stock Exchange',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10815',
        label: 'Merchandiser',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10816',
        label: 'Merchant Banker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10817',
        label: 'Merchant Seaman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10818',
        label: 'Metal Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10819',
        label: 'Meter Collector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10820',
        label: 'Meter Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10821',
        label: 'Meter Reader',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10822',
        label: 'Meter Tester',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10823',
        label: 'Microbiologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10824',
        label: 'Midwife',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10825',
        label: 'Milk Roundsperson',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10826',
        label: 'Milliner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10827',
        label: 'Milling Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10828',
        label: 'Miner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10829',
        label: 'Minicab Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10830',
        label: 'Minister (Religious)',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10831',
        label: 'Mobile Crane Driver (Ground Level)',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10832',
        label: 'Model',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10833',
        label: 'Money Broker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10834',
        label: 'Montessori Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10835',
        label: 'Mortgage Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10836',
        label: 'Mortician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10837',
        label: 'Mother And Housewife',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10838',
        label: 'Motor Car Dealer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10839',
        label: 'Motor Cycle Courier',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10840',
        label: 'Motor Mechanic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10841',
        label: 'Motorbike Mechanic',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10842',
        label: 'Motorway Maintenance',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10843',
        label: 'Museum Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10844',
        label: 'Musician',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10845',
        label: 'Nanny',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10846',
        label: 'National Hunt Jockey',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10847',
        label: 'Neurologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10848',
        label: 'News Correspondent',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10849',
        label: 'News Editor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10850',
        label: 'Newsagent',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10851',
        label: 'Newspaper Reporter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10852',
        label: 'Newsreader',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10853',
        label: 'Night Club Owner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10854',
        label: 'Night Porter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10855',
        label: 'Not Currently Employed',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10856',
        label: 'Nurse - GP Practise',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10857',
        label: 'Nurse - Psychiatric',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10858',
        label: 'Nursery Worker (Agriculture)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10859',
        label: 'Nursery Worker (Education)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10860',
        label: 'Nursing Home Owner',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10861',
        label: 'Nutritionist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10862',
        label: 'Obstetrician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10863',
        label: 'Occupational Therapist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10864',
        label: 'Off License Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10865',
        label: 'Office Cleaner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10866',
        label: 'Office Messenger',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10867',
        label: 'Office Worker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10868',
        label: 'Oil Rigs - Manual',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10869',
        label: 'Oil Rigs - Non-Manual',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10870',
        label: 'Oncologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10871',
        label: 'Opthalmic Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10872',
        label: 'Optician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10873',
        label: 'Optometrist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10874',
        label: 'Organist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10875',
        label: 'Orthodontist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10876',
        label: 'Orthopaedic Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10877',
        label: 'Orthoptist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10878',
        label: 'Osteopath',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10879',
        label: 'Overhead Linesman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10880',
        label: 'Overseas Voluntary Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10881',
        label: 'P.R. Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10882',
        label: 'Paediatric Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10883',
        label: 'Paediatrician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10884',
        label: 'Paint Sprayer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10885',
        label: 'Painter &amp; Decorator - avg &lt; 30 feet',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10886',
        label: 'Painter &amp; Decorator - avg &gt; 30 feet',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10887',
        label: 'Pallet Maker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10888',
        label: 'Panel Beater',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10889',
        label: 'Paramedic',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10890',
        label: 'Park Keeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10891',
        label: 'Park Ranger',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10892',
        label: 'Parking Attendant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10893',
        label: 'Pastry Chef',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10894',
        label: 'Pathologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10895',
        label: 'Pawnbroker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10896',
        label: 'Payroll Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10897',
        label: 'PC Support Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10898',
        label: 'Pension Administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10899',
        label: 'Pension Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10900',
        label: 'Pension Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10901',
        label: 'Pensioner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10902',
        label: 'Permanent Way Inspector',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10903',
        label: 'Personal Assistant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10904',
        label: 'Personal Financial Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10905',
        label: 'Personal Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10906',
        label: 'Personnel Assistant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10907',
        label: 'Personnel Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10908',
        label: 'Pet Shop Owner',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10909',
        label: 'Petrol Pump Attendant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10910',
        label: 'Pharmaceutical Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10911',
        label: 'Pharmacist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10912',
        label: 'Pharmacy Assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10913',
        label: 'Philatelist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10914',
        label: 'Phlebotomist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10915',
        label: 'Phone Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10916',
        label: 'Photocopy Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10917',
        label: 'Photographer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10918',
        label: 'Photographer (Aerial)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10919',
        label: 'Physical Education Teacher',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10920',
        label: 'Physicist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10921',
        label: 'Physiotherapist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10922',
        label: 'Picture Framer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10923',
        label: 'Picture Restorer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10924',
        label: 'Pilot Airline',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10925',
        label: 'Pilot Helicopter (Commercial)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10926',
        label: 'Pipe Layer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10927',
        label: 'Planning Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10928',
        label: 'Plant Hire Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10929',
        label: 'Plasterer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10930',
        label: 'Plastic Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10931',
        label: 'Plate Layer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10932',
        label: 'Playgroup Leader',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10933',
        label: 'Playschool Assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10934',
        label: 'Plumber',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10935',
        label: 'Plumbing And Heating Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10936',
        label: 'Pneumatic Drill Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10937',
        label: 'Podiatrist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10938',
        label: 'Police',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10939',
        label: 'Political Correspondent',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10940',
        label: 'Politician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10941',
        label: 'Port Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10942',
        label: 'Porter Hotel/Hospital',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10943',
        label: 'Postal Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10944',
        label: 'Postperson - Driving',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10945',
        label: 'Postperson - No Driving',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10946',
        label: 'Post Primary Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10947',
        label: 'Pottery Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10948',
        label: 'Poultry Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10949',
        label: 'Power Station Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10950',
        label: 'Precision Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10951',
        label: 'Press Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10952',
        label: 'Priest',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10953',
        label: 'Primary School Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10954',
        label: 'Principal',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10955',
        label: 'Printer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10956',
        label: 'Prison Officer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10957',
        label: 'Private Detective',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10958',
        label: 'Private Pilot',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10959',
        label: 'Probation Officer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10960',
        label: 'Production Operator',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10961',
        label: 'Professor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10962',
        label: 'Programmer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10963',
        label: 'Property Developer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10964',
        label: 'Psychiatrist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10965',
        label: 'Psychologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10966',
        label: 'Psychotherapist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10967',
        label: 'Public Health Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10968',
        label: 'Public Relations Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10969',
        label: 'Publican',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v10970',
        label: 'Publisher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10971',
        label: 'Quality Controller',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10972',
        label: 'Quantity Surveyor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10973',
        label: 'Quarry Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10974',
        label: 'Racehorse Jockey',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10975',
        label: 'Racehorse Trainer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10976',
        label: 'Radio Presenter',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10977',
        label: 'Radiographer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10978',
        label: 'Radiologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10979',
        label: 'Radiotherapist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10980',
        label: 'Railway - Signaler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10981',
        label: 'Railway - Guard',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10982',
        label: 'Railway - Maintenance',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10983',
        label: 'Railway - Ticket Collector / Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10984',
        label: 'Railway Crossing Keeper',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10985',
        label: 'Receptionist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10986',
        label: 'Recruitment Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10987',
        label: 'Redundant',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10988',
        label: 'Reflexology Consultant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10989',
        label: 'Refuse Collector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10990',
        label: 'Regional Sales Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10991',
        label: 'Registered Child Minder',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10993',
        label: 'Removal Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v10994',
        label: 'Reporter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10995',
        label: 'Researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v10996',
        label: 'Restaurant Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10997',
        label: 'Restauranteur',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v10998',
        label: 'Restorer (Furniture)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v10999',
        label: 'Retailer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11000',
        label: 'Retired',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11001',
        label: 'Revenue Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11002',
        label: 'Rheumatologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11003',
        label: 'Rigger (Oil/Gas Rigs)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11004',
        label: 'Rigger (Onshore)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11005',
        label: 'Road Maintenance Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11006',
        label: 'Road Safety Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11007',
        label: 'Roofer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11008',
        label: 'Sacristan',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11009',
        label: 'Safety Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11010',
        label: 'Safety Training Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11011',
        label: 'Sailor (Merchant Marine)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11012',
        label: 'Sales Assistant - Light Goods',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11013',
        label: 'Sales Assistant - Shop',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11014',
        label: 'Sales Executive',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11015',
        label: 'Salesperson/Commercial Traveller',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11016',
        label: 'Salmon Farmer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11017',
        label: 'Sander',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11018',
        label: 'Satellite Dish Installer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11019',
        label: 'Saw Doctor',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11020',
        label: 'Sawmill Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11021',
        label: 'Scaffolder',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11022',
        label: 'School Caretaker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11024',
        label: 'Schools Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11025',
        label: 'Scientific Researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11026',
        label: 'Scrapman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11027',
        label: 'Seaman (Merchant Marine)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11028',
        label: 'Seamstress',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11029',
        label: 'Search Rescue Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11030',
        label: 'Service Co-ordinator/Supervisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11031',
        label: 'Secondary School Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11032',
        label: 'Secretary',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11033',
        label: 'Security Guard',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11034',
        label: 'Security Van Driver',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11035',
        label: 'Senior Executive Officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11036',
        label: 'Service Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11037',
        label: 'Sewage Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11038',
        label: 'Sewing Machinist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11039',
        label: 'Share Dealer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11040',
        label: 'Sheet Metal Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11041',
        label: 'Shipbuilder',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11042',
        label: 'Shipping Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11043',
        label: 'Ships Captain',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11044',
        label: 'Ships Steward',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11045',
        label: 'Shoe Maker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11046',
        label: 'Shoe Repairer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11047',
        label: 'Shop Assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11048',
        label: 'Shop Detective',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11049',
        label: 'Shop Steward',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11050',
        label: 'Shopfitter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11051',
        label: 'Shopkeeper - Light Goods',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11052',
        label: 'Shunter',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11053',
        label: 'Sign Erector - Greater than 30ft',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11054',
        label: 'Sign Erector - Less than 30ft',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11055',
        label: 'Signalman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11056',
        label: 'Silversmith',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11057',
        label: 'Singing Teacher',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11058',
        label: 'Site Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11059',
        label: 'Sky Dish Installer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11060',
        label: 'Slater',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11061',
        label: 'Social Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11062',
        label: 'Software Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11063',
        label: 'Soldier',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11064',
        label: 'Solicitor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11065',
        label: 'Sorting Office Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11066',
        label: 'Sound Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11067',
        label: 'Sound Technician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11068',
        label: 'Speech Therapist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11069',
        label: 'Sports Reporter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11070',
        label: 'Spray Painter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11071',
        label: 'Stable Hand',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11072',
        label: 'Staff Nurse',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11073',
        label: 'Station Master',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11074',
        label: 'Statistician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11075',
        label: 'Steel Erector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11076',
        label: 'Steelworker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11077',
        label: 'Steeplejack',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11078',
        label: 'Stevedore',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11079',
        label: 'Stock Broker',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11080',
        label: 'Stockroom Controller',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11081',
        label: 'Stone Mason',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11082',
        label: 'Store Detective',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11083',
        label: 'Store Manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11084',
        label: 'Street Cleaner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11085',
        label: 'Street Market Trader',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11086',
        label: 'Structural Engineer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11087',
        label: 'Student',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11088',
        label: 'Supermarket Worker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11089',
        label: 'Surgeon',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11090',
        label: 'Surveyor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11091',
        label: 'Swimming Pool Attendant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11092',
        label: 'Switchboard Operator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11093',
        label: 'Systems Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11094',
        label: 'Systems Programmer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11095',
        label: 'Tailor',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11096',
        label: 'Takeaway Owner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11097',
        label: 'Tanner',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11098',
        label: 'Tarmac Layer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11099',
        label: 'Tax Advisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11100',
        label: 'Tax Inspector',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11101',
        label: 'Taxi Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11102',
        label: 'Taxidermist',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11104',
        label: 'Tealady',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11105',
        label: 'Team Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11106',
        label: 'Telecom Installer/Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11107',
        label: 'Telephonist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11109',
        label: 'Television Engineer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11110',
        label: 'Television Producer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11111',
        label: 'Theatre Sister',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11112',
        label: 'Theatrical Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11113',
        label: 'Therapist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11114',
        label: 'Ticket Clerk',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11115',
        label: 'Ticket Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11116',
        label: 'Tiler - Floor/Wall',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11117',
        label: 'Tiler - Roof',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11118',
        label: 'Timber Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11119',
        label: 'Toolmaker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11120',
        label: 'Trade Union Official',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11121',
        label: 'Traffic Warden',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11122',
        label: 'Train Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11123',
        label: 'Trainee Accountant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11124',
        label: 'Trainee Doctor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11125',
        label: 'Training Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11126',
        label: 'Travel Consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11127',
        label: 'Treasurer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11128',
        label: 'Tree Surgeon',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11129',
        label: 'Truck Driver',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11130',
        label: 'Tunneller',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11131',
        label: 'Turf Accountant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11132',
        label: 'Turner Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11133',
        label: 'TV Cameraman',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11134',
        label: 'TV Presenter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11135',
        label: 'TV Repairer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11136',
        label: 'Typesetter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11137',
        label: 'Typist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11138',
        label: 'Tyre and Exhaust Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11139',
        label: 'Undertaker',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11140',
        label: 'Underwriter',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11141',
        label: 'Unemployed',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11142',
        label: 'University Lecturer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11143',
        label: 'University Student',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11144',
        label: 'Upholsterer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11145',
        label: 'Valet',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11146',
        label: 'Valuer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11147',
        label: 'Van Driver/Delivery Person',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11148',
        label: 'Vat Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11149',
        label: 'Vegetable Supplier',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11150',
        label: 'Vehicle Inspector',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11151',
        label: 'Veterinary Surgeon - Large Animal',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11152',
        label: 'Vet surgeon small animals',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11153',
        label: 'Vice Principal',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11154',
        label: 'Victualler',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11155',
        label: 'Vintner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11156',
        label: 'Vocational Training Instructor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11157',
        label: 'Voluntary Work',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11158',
        label: 'Wages Clerk',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11159',
        label: 'Waiter/Waitress',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11160',
        label: 'Wall Tiler',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11161',
        label: 'Warden (Prison Service)',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11162',
        label: 'WareHouse Person',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11163',
        label: 'Waste Disposal Collector',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11164',
        label: 'Welder',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11165',
        label: 'Wholesaler',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11166',
        label: 'Wife and Mother',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11167',
        label: 'Window Cleaner - Less than 30ft',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11168',
        label: 'Window Cleaner - Other',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11169',
        label: 'Window Dresser',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11170',
        label: 'Window Installer &lt; 30 ft',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11171',
        label: 'Window Installer &gt; 30 ft',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11172',
        label: 'Windscreen Fitter',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11173',
        label: 'Wine Bar Owner',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11174',
        label: 'Wood Worker',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11175',
        label: 'Works Foreman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11176',
        label: 'Writer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11177',
        label: 'X-Ray Technician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11178',
        label: 'Youth Worker',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11179',
        label: 'Zoo Keeper',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11180',
        label: 'Zoologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11181',
        label: 'Buyer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11182',
        label: 'Editor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11183',
        label: 'Watch Maker/Repairer',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11222',
        label: 'Dental Hygienist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11242',
        label: 'Professional Golfer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11243',
        label: 'Professional rugby player',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11244',
        label: 'Professional footballer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11262',
        label: 'Projectionist',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11282',
        label: 'Website Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11283',
        label: 'Website Specialist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11284',
        label: 'Website Creator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11285',
        label: 'Internet Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11286',
        label: 'Internet Specialist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11290',
        label: 'Manual Worker',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11291',
        label: 'Sports Player',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11292',
        label: 'Sportsman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11293',
        label: 'Sportswoman',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11294',
        label: 'Professional Sports Person',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11295',
        label: 'Company Representative',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11296',
        label: 'Cleaning Person',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11297',
        label: 'Event Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11301',
        label: 'Architectural Technologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11302',
        label: 'Event Organiser',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11303',
        label: 'Promotions Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11304',
        label: 'Promotions Assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11322',
        label: 'Sports Development Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11323',
        label: 'Sports coach',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11342',
        label: 'Manager - non-clerical',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11343',
        label: 'Company director - manual',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11344',
        label: 'Co-director - manual',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11347',
        label: 'Co-director - non-manual',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11401',
        label: 'Assistant bank manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11402',
        label: 'IT technician',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11403',
        label: 'IT administrator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11404',
        label: 'Production control manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11405',
        label: 'Commercial director',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11406',
        label: 'Goldsmith',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11407',
        label: 'Information officer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11408',
        label: 'Accounts assistant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11409',
        label: 'Retail manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11410',
        label: 'Finance manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11411',
        label: 'Process technician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11412',
        label: 'Technical assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11413',
        label: 'Operations manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11414',
        label: 'Sales manager',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11415',
        label: 'Supervisor',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11416',
        label: 'Sales rep',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11417',
        label: 'Postman',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11418',
        label: 'Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11441',
        label: 'Blocklayer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11442',
        label: 'IT manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11443',
        label: 'Property supervisor',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11444',
        label: 'Care assistant',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11445',
        label: 'Retail assistant',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11446',
        label: 'Theatre worker (surgical)',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11447',
        label: 'Special Needs Assistant (school)',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11448',
        label: 'Community Welfare Officer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11449',
        label: 'Technician',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11461',
        label: 'Project director',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11462',
        label: 'Project manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11463',
        label: 'Revenue manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11464',
        label: 'Quality analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11465',
        label: 'Florist',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11466',
        label: 'Tour guide',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11467',
        label: 'Human resources consultant',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11468',
        label: 'Navy officer',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11470',
        label: 'Cashier (office/bank/building society)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11471',
        label: 'Chemical analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11472',
        label: 'Clothes designer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11473',
        label: 'Financial services (includes insurance brokers agents)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11474',
        label: 'Managing director (no manual work)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11475',
        label: 'Practice manager (gp surgery)',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11476',
        label: 'Town planner',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11481',
        label: 'Army Ranger',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11482',
        label: 'Nurse - Hospital',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11490',
        label: 'Default Over 50s Occupation',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11502',
        label: 'Veterinary Nurse',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11523',
        label: 'Process operator',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11524',
        label: 'Farm Owner',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11525',
        label: 'Family Farm fulltime employee',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11526',
        label: 'Palliative Care Nurse',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11527',
        label: 'Director of Nursing',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11528',
        label: 'Clinical Nurse Manager',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11529',
        label: 'Public Health Nurse ',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11530',
        label: 'Social Care Worker ',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11531',
        label: 'Nail Technician',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11532',
        label: 'IT Translator',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11533',
        label: 'Dog Walker ',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11534',
        label: 'Claims Assessor - Office Based',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11535',
        label: 'Call Centre Worker - Sales',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11536',
        label: 'Call Centre Worker - Non Sales',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11537',
        label: 'Wind Turbine Engineer',
        BillPayOccupationClass: '4',
    },
    {
        value: 'v11538',
        label: 'Barista',
        BillPayOccupationClass: '3',
    },
    {
        value: 'v11539',
        label: 'Animator CGI',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11540',
        label: 'Master Brewer',
        BillPayOccupationClass: '2',
    },
    {
        value: 'v11542',
        label: 'Handyman ',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11543',
        label: 'Mushroom Picker ',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11544',
        label: 'Meat Factory Worker ',
        BillPayOccupationClass: 'D',
    },
    {
        value: 'v11545',
        label: 'Project Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11546',
        label: 'Quality Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11547',
        label: 'Process Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11548',
        label: 'Validation Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11549',
        label: 'Automation Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11550',
        label: 'Quality Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11552',
        label: 'IT Project Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11553',
        label: 'Software Systems Developer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11554',
        label: 'Software Tester',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11555',
        label: 'Systems Analyst Architect',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11557',
        label: 'Web / User Interface Designer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11558',
        label: 'Information Security Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11559',
        label: 'Cyber Security Engineer / Manager',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11560',
        label: 'Data Engineer',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11561',
        label: 'Data Analyst',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11563',
        label: 'IT Support ',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11564',
        label: 'Food Technologist',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11565',
        label: 'Food science researcher',
        BillPayOccupationClass: '1',
    },
    {
        value: 'v11566',
        label: 'Agronomist ',
        BillPayOccupationClass: '1',
    },
]
