import {
    getPlans,
    getOnePlanParams,
} from 'common/components/templates/Calculator/config/onePlanConfig'

export const getOnePlanCovers = (input: any) => {
    return getPlans(
        input.jointLife,
        Number(input.age1),
        Number(input.repayTerm),
        input.isMarried,
        Number(input.income1),
        Number(input.childNum),
        Number(input.childAge),
        mortgageOrRentPayment(input),
        input.specifiedIllnessCover ? Number(input.specifiedIllnessCover) : 0,
        input.isAdditionalLifeCover,
        input.lifeCoverAmt ? Number(input.lifeCoverAmt) : undefined,
        Number(input.age2),
        Number(input.income2),
        input.specifiedIllnessCover2 ? Number(input.specifiedIllnessCover2) : 0,
        input.isAdditionalLifeCover2,
        input.lifeCoverAmt ? Number(input.lifeCoverAmt) : undefined
    )
}

export const getApiParamsOnePlan = (
    input: any,
    type: string,
    occupations: any
) => {
    const quoteData = {
        smokerCd1: input.isSmoker,
        smokerCd2: input.isSmoker2,
        age1: Number(input.age1),
        age2: Number(input.age2),
        mortgageTerm:
            input.mortgageOrRent === 'Mortgage' ? Number(input.repayTerm) : '',
        mortgagePayment: mortgageOrRentPayment(input),
        maritalStatus1: input.isMarried,
        childNum1: input.childNum,
        youngestChild1: Number(input.childAge),
        income1: Number(input.income1),
        income2: Number(input.income2),
        lifeCoverAmt1: input.lifeCoverAmt
            ? Number(input.lifeCoverAmt)
            : undefined,
        lifeCoverConversion1: input.isAdditionalLifeCover,
        specifiedIllnessAmt1: input.specifiedIllnessCover
            ? Number(input.specifiedIllnessCover)
            : undefined,
        specifiedIllnessConversion1: input.isSpecifiedIllnessCover,
        jointLife: input.jointLife,
        occupationSelect1: input.occupation,
        occupationSelect2: input.occupation2,
        billPayDeferredWeeks1: 13,
        lifeCoverAmt2: input.lifeCoverAmt2
            ? Number(input.lifeCoverAmt2)
            : undefined,
        lifeCoverConversion2: input.isAdditionalLifeCover2,
        specifiedIllnessAmt2: input.specifiedIllnessCover2
            ? Number(input.specifiedIllnessCover2)
            : undefined,
        specifiedIllnessConversion2: input.isSpecifiedIllnessCover2,
        incomeProtection: input.isAddtionalProtectionBillCover,
        incomeProtection2: input.isAddtionalProtectionBillCover2,
    }

    return getOnePlanParams(quoteData, type, occupations)
}
const mortgageOrRentPayment = (input: any) => {
    return input.mortgageOrRent === 'Mortgage'
        ? Number(input.mortgagePayment)
        : input.mortgageOrRent === 'Rent'
        ? Number(input.rentAmount)
        : 0
}
